/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable camelcase */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Descriptions, Form, Row, Switch, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
//
import { addOrUpdateEmployee } from 'appRedux/actions/Pagination';
import { EmployeeImgForm } from 'components/Employee';
import FormModal from 'components/FormModal';
import Widget from 'components/Widget';

const bg_banner = require('assets/images/widget/profile_bg.png')
const edit_icon = require('assets/images/layouts/edit.png');

const { Title } = Typography
const EmployeeBanner = ({ user, editable = true }) => {

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const { name, parent, designation, photo, mobile, email, eid, blood_group, company, enabled, office } = user ?? {}

  const handleOk = (value) => {
    try {
      const allowedFormats = ['image/png', 'image/jpeg'];
      const file = value.photo?.file?.originFileObj;
      console.log(value);

      if (file && !allowedFormats.includes(file.type)) {
        message.error('Only PNG and JPEG formats are allowed.');
        setVisible(false);
        return Promise.reject('Invalid file format');
      }

      value.id = user.id;
      return dispatch(addOrUpdateEmployee(value))
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          message.error(`Image upload failed: ${error.message}`);
        });
    } catch (error) {
      if (error instanceof TypeError) {
        message.error('Failed to process the image. Please ensure it is a valid PNG or JPEG file.');
      } else {
        message.error(`An unexpected error occurred: ${error.message}`);
      }
    }
  };

  return (
    <Widget styleName="gx-card-full">
      <div className="gx-slider-img">
        <img alt="banner" src={bg_banner} width="100%" style={{ maxHeight: 185 }} />
        <Avatar
          size={{ xs: 120, sm: 120, md: 82, lg: 82, xl: 100, xxl: 150 }}
          src={photo?.big}
          icon={<UserOutlined />}
          style={{
            position: 'absolute',
            bottom: '-40%',
            left: '2%',
            objectFit: 'cover',
            border: '4px solid #FFF',
            borderRadius: '100%',
          }}
        />
      </div>
      <Row>
        <Col span={3} />
        <Col span={4}>
          <Title level={4} className="gx-mb-1">
            {name} {" "}<small>({designation?.name})</small>
          </Title>
          <div className="gx-jr-db-label">ID :{eid}</div>
        </Col>
        <Col span={8}>
          <Descriptions column={1} labelStyle={{ width: 150 }} size="small">
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Company
                </span>
              }
            >
              <span className="gx-jr-db-label">{company?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Office
                </span>
              }
            >
              <span className="gx-jr-db-label">{office?.name}</span>
            </Descriptions.Item>
          </Descriptions>
          <Switch
            checkedChildren="ENABLE"
            unCheckedChildren="DISABLE"
            checked={enabled}
          />
        </Col>
        <Col span={9}>
          <Descriptions column={1} labelStyle={{ width: 150, marginBottom: 0 }}>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Mobile
                </span>
              }
            >
              <span className="gx-jr-db-label">{mobile}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Email
                </span>
              }
            >
              <span className="gx-jr-db-label">{email}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Blood Group
                </span>
              }
            >
              <span className="gx-jr-db-label">{blood_group?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={<span
                className="gx-jr-db-label"
                style={{
                  display: 'inline-block',
                  width: '150px'
                }}
              >
                Line Manager
              </span>}
            >
              <span className="gx-jr-db-label">{parent?.name}</span>
            </Descriptions.Item>

          </Descriptions>
        </Col>
      </Row>
      {editable && (
        <div
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
          }}
          onClick={() => {
            setVisible(true);
            form.setFieldsValue({
              photo: photo?.big,
            });
          }}
        >
          <img
            alt='edit'
            src={edit_icon}
            style={{ height: 34, width: 34, cursor: 'pointer' }}
          />
        </div>
      )}
      {visible && (
        <FormModal
          {...{
            visible,
            width: 300,
            handleCancel: () => setVisible(false),
            handleOk,
            title: 'Update Profile Picture',
            form,
            // eslint-disable-next-line react/display-name
            component: () => (
              <EmployeeImgForm
                {...{
                  employee_id: user?.id,
                  isEdit: true,
                  form,
                }}
              />
            ),
          }}
        />
      )}
    </Widget>
  )
}

EmployeeBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
}

export default EmployeeBanner
