/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable indent */
import {
    Badge,
    Button,
    Card,
    Col,
    Layout,
    Row,
    Typography
} from 'antd';
import {
    getHRSById,
    handleHRServiceActions
} from 'appRedux/actions';
import { selectPaginationItem } from 'appRedux/reducers/Pagination';

import Widget from 'components/Widget';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Auxiliary from 'util/Auxiliary';
import { HRS_STATUS_BG, PAGINATION_TYPES } from 'util/constants';

const { Content, Footer } = Layout;
const { Title, Text } = Typography;
const actions = {
    APPROVE: '/approve',
    REJECT: '/cancel',
}

const ServiceView = () => {
    const { id: hrsId } = useParams();
    const dispatch = useDispatch();

    const HRS = useSelector(
        selectPaginationItem({ key: PAGINATION_TYPES.hr_services, id: hrsId })
    );

    useEffect(() => {
        dispatch(getHRSById(hrsId));
    }, [hrsId, dispatch]);

    const handleActions = (TYPE) => {
        const values = {
            key: PAGINATION_TYPES.hr_services,
            route: actions[TYPE],
            id: hrsId,
        };
        dispatch(handleHRServiceActions(values));
    };

    return (
        <Auxiliary>
            <h3 style={{ padding: '12px 0 16px 8px' }}>HR Service</h3>
            <Widget>
                <Layout style={{ background: '#f5f5f5' }}>
                    <Content style={{ margin: '20px' }}>
                        <Card style={{ padding: '20px' }}>
                            <Row gutter={16} align="top">
                                <Col xs={24} sm={12} md={12} lg={12}>
                                    <Row>
                                        <Col span={24}>
                                            <img
                                                src={HRS?.employee?.photo?.big}
                                                alt={HRS?.employee?.name}
                                                style={{ width: 150, height: 130, marginBottom: '16px' }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Title level={4}>{HRS?.employee?.name}</Title>
                                            <Text>
                                                {HRS?.employee?.designation?.name}
                                                <br />
                                                EID - {HRS?.employee?.eid}
                                                <br />
                                                {HRS?.employee?.company?.name}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={10} sm={4} md={4} lg={4}></Col>
                                <Col xs={18} sm={8} md={8} lg={8} style={{ marginTop: HRS?.category_name === 'noc' ? '90px' : '130px' }}>
                                    <Row>
                                        <Col span={24}>
                                            <Row justify="space-between" align="middle" style={{ marginBottom: '8px' }}>
                                                <Col span={10}>
                                                    <Text>Requested at</Text>
                                                </Col>
                                                <Col span={4}>:</Col>
                                                <Col span={10}>
                                                    <Text strong>{moment(HRS?.issue_date).format('DD MMM, YYYY')}</Text>
                                                </Col>
                                            </Row>
                                            <Row justify="space-between" align="middle" style={{ marginBottom: '8px' }}>
                                                <Col span={10}>
                                                    <Text>Request for</Text>
                                                </Col>
                                                <Col span={4}>:</Col>
                                                <Col span={10}>
                                                    <Text strong>{HRS?.category_name}</Text>
                                                </Col>
                                            </Row>
                                            {HRS?.type && (
                                                <Row justify="space-between" align="middle" style={{ marginBottom: '8px' }}>
                                                    <Col span={10}>
                                                        <Text>NOC Type</Text>
                                                    </Col>
                                                    <Col span={4}>:</Col>
                                                    <Col span={10}>
                                                        <Text strong>{HRS?.type?.name}</Text>
                                                    </Col>
                                                </Row>
                                            )}
                                            {HRS?.reason && (
                                                <Row justify="space-between" align="middle" style={{ marginBottom: '8px' }}>
                                                    <Col span={10}>
                                                        <Text>Reason</Text>
                                                    </Col>
                                                    <Col span={4}>:</Col>
                                                    <Col span={10}>
                                                        <Text strong>{HRS?.reason}</Text>
                                                    </Col>
                                                </Row>
                                            )}
                                            {HRS?.to_date && HRS?.from_date && (
                                                <Row justify="space-between" align="middle" style={{ marginBottom: '8px' }}>
                                                    <Col span={10}>
                                                        <Text>Date Range</Text>
                                                    </Col>
                                                    <Col span={4}>:</Col>
                                                    <Col span={10}>
                                                        <Text strong>
                                                            {moment(HRS?.from_date).format('DD MMM, YYYY')} - {moment(HRS?.to_date).format('DD MMM, YYYY')}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            )}
                                            {HRS?.status?.id === 3 && (
                                                <Row justify="space-between" align="middle">
                                                    <Col span={10}>
                                                        <Text>Approved by</Text>
                                                    </Col>
                                                    <Col span={4}>:</Col>
                                                    <Col span={10}>
                                                        <Text strong>{HRS?.accountability?.approved_by?.name}</Text>
                                                    </Col>
                                                </Row>
                                            )}
                                            {HRS?.status?.id === 4 && (
                                                <Row justify="space-between" align="middle">
                                                    <Col span={10}>
                                                        <Text>Rejected by</Text>
                                                    </Col>
                                                    <Col span={4}>:</Col>
                                                    <Col span={10}>
                                                        <Text strong>{HRS?.accountability?.cancelled_by?.name}</Text>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row justify="space-between" align="middle" style={{ marginTop: '8px' }}>
                                                <Col span={10}>
                                                    <Text>Status</Text>
                                                </Col>
                                                <Col span={4}>:</Col>
                                                <Col span={10}>
                                                    <Badge count={HRS?.status?.name} style={{ backgroundColor: HRS_STATUS_BG[HRS?.status?.id] }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Card style={{ marginTop: 20, backgroundColor: '#f8f8f8' }}>
                                <Title level={5}>Description</Title>
                                <Text>{HRS?.description}</Text>
                            </Card>
                        </Card>
                    </Content>
                    {
                        ((HRS?.status?.id !== 3 && HRS?.status?.id !== 5 && HRS?.status?.id !== 4 && HRS?.status?.id === 2)) && (
                            <Footer style={{ textAlign: 'right', background: '#fff' }}>
                                <Button
                                    type="danger"
                                    shape="round"
                                    htmlType="submit"
                                    style={{ padding: '2px 30px 30px 30px' }}
                                    onClick={() => handleActions('REJECT')}
                                >
                                    Reject
                                </Button>
                                <Button
                                    type="primary"
                                    shape="round"
                                    htmlType="submit"
                                    style={{ padding: '2px 30px 30px 30px' }}
                                    onClick={() => handleActions('APPROVE')}
                                >
                                    Approve
                                </Button>
                            </Footer>
                        )
                    }
                </Layout>
            </Widget>
        </Auxiliary >
    );
};

export default ServiceView;
