/* eslint-disable camelcase */
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
//
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import {
  addOrUpdateHRS,
  deleteHRService,
  fetchCompanies,
  fetchHRS,
  fetchStaticItem,
  handleHRServiceActions,
} from 'appRedux/actions'
import EmployeeCell from 'components/EmployeeCell'
import FormModal from 'components/HRServicesCreate'
import { EmployeeSearchBox } from 'components/SearchBox'
import Widget from 'components/Widget'
import { HRService } from 'services'
import { REST_API } from 'services/rest.api'
import Auxiliary from 'util/Auxiliary'
import {
  DATE_FORMAT,
  getDateFormat,
  HRS_STATUS_BG,
  PAGINATION_LIMIT,
  PAGINATION_TYPES,
} from 'util/constants'
import {
  getInitialSearch,
  setSearchInLocalStorage,
  showDeleteConfirm,
  sortColumnObject,
} from 'util/helpers'

const actions = {
  SUBMIT: '/submit',
  APPROVE: '/approve',
  REJECT: '/cancel',
  DONE: '/done',
}
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

const initialSearch = {
  employee_id: '',
  status_id: '',
  company_id: '',
  category: '',
  submitted_range: [],
}

const HRServices = () => {
  const HRSListType = localStorage.getItem('HRS_LIST_TYPE') ?? 'own'
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const res = localStorage.getItem('currentUser')
  const user = JSON.parse(res)

  const { HRS, Company, ServiceRequestStatus, ServiceCategories, noc_type, isLoading } =
    useSelector(({ pagination, common, loading }) => ({
      Company: pagination[PAGINATION_TYPES.companies]?.items ?? [],
      HRS: pagination[PAGINATION_TYPES.hr_services],
      ServiceCategories: common[REST_API.serviceRC] ?? [],
      ServiceRequestStatus: common[REST_API.serviceRS] ?? [],
      noc_type: common[REST_API.serviceRNT] ?? [],
      isLoading: loading[PAGINATION_TYPES.hr_services],
    }))
  const [listType, setListType] = useState(HRSListType)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const { url } = useRouteMatch()
  const [search, setSearch] = useState(getInitialSearch(initialSearch, url))
  const [visible, setVisible] = useState(false)
  const [editItem, setEditItem] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [sort, setSort] = useState(JSON.parse(localStorage.getItem(`${url}/sort`)) || '')
  const handleInitial = useCallback(
    (_page, limit = PAGINATION_LIMIT) => {
      setCurrent(_page)
      const { employee_id, ...rest } = search || {}
      const query = {
        list_type: listType,
        sort: 'id,desc',
        page: _page,
        limit,
        ...rest,
        ...(sort && { sort }),
        ...(listType !== 'own' && { employee_id }),
      }
      dispatch(fetchHRS(query))
    },
    [dispatch, search, listType, sort]
  )

  useEffect(() => {
    if (search) {
      setSearchInLocalStorage(search, url)
    }
  }, [search, url])

  useEffect(() => {
    if (sort) {
      localStorage.setItem(`${url}/sort`, JSON.stringify(sort))
    }
  }, [sort])

  useEffect(() => {
    handleInitial(1)
  }, [handleInitial])

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchStaticItem(REST_API.serviceRC, HRService))
    dispatch(fetchStaticItem(REST_API.serviceRS, HRService))
    dispatch(fetchStaticItem(REST_API.serviceRNT, HRService))
  }, [dispatch])

  const handleChange = (value = '', key) => {
    setSearch((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleDateChange = (dates, key) => {
    if (!dates?.length) {
      handleChange([], key)
      return
    }
    handleChange([dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')], key)
  }

  const data = useMemo(() => HRS?.items ?? [], [HRS])
  const pagination = {
    current,
    hideOnSinglePage: true,
    position: 'both',
    total: HRS.total,
    pageSizeOptions: [10, 20, 50],
    showQuickJumper: true,
    onChange: (page, pageSize) => handleInitial(page, pageSize),
  }

  const handleEdit = (item) => {
    const {
      category_name,
      from_date,
      to_date,
      passport_no,
      intended_country,
      description,
      issue_date,
      reason,
      type,
    } = item

    setSelectedCategory(category_name || null)

    form.setFieldsValue({
      category: category_name || undefined,
      description: description || undefined,
      intended_country: intended_country || undefined,
      passport_no: passport_no || undefined,
      issue_date: issue_date ? getDateFormat(issue_date) : undefined,
      from_date: from_date ? getDateFormat(from_date) : undefined,
      to_date: to_date ? getDateFormat(to_date) : undefined,
      reason: reason || undefined,
      noc_type_id: type?.id,
    })

    setVisible(true)
    setEditItem(item)
  }

  const handleDelete = (item) => {
    showDeleteConfirm(<ExclamationCircleOutlined />, 'HRService', () => {
      return dispatch(deleteHRService(item.id))
    })
  }
  const handleActions = (item, TYPE) => {
    const values = {
      key: PAGINATION_TYPES.hr_services,
      route: actions[TYPE],
      id: item.id,
    }
    dispatch(handleHRServiceActions(values))
  }

  const columns = [
    {
      title: 'EMPLOYEE',
      dataIndex: 'employee',
      render: (employee, record) => {
        const { name, photo, eid } = employee ?? {}
        return (
          <>
            {
              listType === 'team' ? (
                <Link to={'/hr-services/' + record?.id}>
                  <EmployeeCell {...{ name, photo: photo?.small, eid }} />
                </Link>
              ) : <EmployeeCell {...{ name, photo: photo?.small, eid, link: false }} />
            }
          </>
        )
      },
      ...sortColumnObject('employee_id', setSort, sort),
    },
    {
      title: 'COMPANY',
      dataIndex: 'employee',
      render: (employee) => <div className="gx-text-link">{employee?.company?.name}</div>,
      ...sortColumnObject('company_id', setSort, sort),
    },
    {
      title: 'LINE MANAGER',
      dataIndex: 'line_manager',
      render: (line_manager) => <div className="gx-text-link">{line_manager?.name}</div>,
    },
    {
      title: 'REQUEST FOR',
      dataIndex: 'category_name',
      render: (_) => (
        <div className="gx-text-link">
          <div>{_}</div>
        </div>
      ),
      ...sortColumnObject('category', setSort, sort),
    },
    {
      title: 'REQUEST AT',
      dataIndex: 'accountability',
      render: (accountability) => {
        return <div className="gx-text-link">{moment(accountability?.created).format('DD-MM-YYYY - HH:mm')}</div>
      },
      ...sortColumnObject('issue_date', setSort, sort),
    },
    {
      title: 'SUBMITTED BY',
      dataIndex: 'accountability',
      render: (accountability) => {
        return <div className="gx-text-link">{accountability?.submitted !== null ? moment(accountability?.submitted).format('DD-MM-YYYY - HH:mm') : null}</div>
      },
      ...sortColumnObject('issue_date', setSort, sort),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: 130,
      render: (_) => {
        return <Badge count={_?.name} style={{ backgroundColor: HRS_STATUS_BG[_?.id] }} />
      },
      ...sortColumnObject('status_id', setSort, sort),
    },
    {
      title: 'ACTION',
      width: 100,
      key: 'action',
      fixed: 'right',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const statusID = record?.status?.id
        const menu = (
          <Menu>
            <Menu.Item
              key="edit" disabled={statusID !== 1} onClick={() => handleEdit(record)}
            >
              <EditOutlined />
              EDIT
            </Menu.Item>
            <Menu.Item
              key="delete" disabled={statusID !== 1} onClick={() => handleDelete(record)}
            >
              <DeleteOutlined />
              DELETE
            </Menu.Item>
            <Menu.Item
              key="submit"
              disabled={statusID !== 1}
              onClick={() => handleActions(record, 'SUBMIT')}
            >
              <CheckCircleTwoTone twoToneColor={HRS_STATUS_BG[2]} />
              SUBMIT
            </Menu.Item>
            <Menu.Item
              key="approve"
              disabled={statusID !== 2 || listType === 'own'}
              onClick={() => handleActions(record, 'APPROVE')}
            >
              <CheckCircleTwoTone twoToneColor={HRS_STATUS_BG[3]} />
              APPROVE
            </Menu.Item>
            <Menu.Item
              key="reject"
              disabled={statusID !== 2 || listType === 'own'}
              onClick={() => handleActions(record, 'REJECT')}
            >
              <CloseCircleTwoTone twoToneColor={HRS_STATUS_BG[6]} />
              REJECT
            </Menu.Item>
            {/* <Menu.Item
              key="done"
              disabled={statusID !== 2 || statusID !== 1 || listType === 'own'}
              onClick={() => handleActions(record, 'DONE')}
            >
              <CheckCircleTwoTone twoToneColor={HRS_STATUS_BG[4]} />
              DONE
            </Menu.Item> */}
          </Menu>
        )
        return (
          <Dropdown
            overlay={menu} placement="bottomCenter" arrow
          >
            <ControlOutlined />
          </Dropdown>
        )
      },
    },
  ]

  const renderData = () => (
    <Row justify="center" align="middle">
      <Col span={listType === 'own' ? 6 : 4}>
        <Tooltip title="Create Leave Application">
          <Button
            shape="circle"
            type="primary"
            className="gx-mt-3"
            onClick={() => {
              setEditItem(null)
              setVisible(true)
            }}
          >
            <span className="gx-fs-xxl">+</span>
          </Button>
        </Tooltip>
        <span>
          <Radio.Group
            options={[
              { label: 'Self', value: 'own' },
              { label: 'Team', value: 'team' },
            ]}
            onChange={(e) => {
              setListType(e?.target?.value)
              localStorage.setItem('HRS_LIST_TYPE', e?.target?.value)
            }}
            value={listType}
            optionType="button"
            buttonStyle="solid"
          />
        </span>
      </Col>
      {listType === 'own' ? null : (
        <Col span={4}>
          <EmployeeSearchBox
            searchFilter
            value={search?.employee_id}
            disabled={listType === 'own'}
            onChange={(item) => handleChange(item?.value?.split('#')[0], 'employee_id')}
          />
        </Col>
      )}
      <Col span={listType === 'own' ? 6 : 4}>
        <Select
          allowClear
          className="gx-w-100"
          placeholder="Select Status"
          onChange={(item) => handleChange(item, 'status_id')}
          defaultValue={search?.status_id === '' ? null : search?.status_id}
          options={ServiceRequestStatus?.map((i) => ({ value: i.id, label: i.name }))}
        />
      </Col>
      {listType === 'own' ? null : (
        <Col span={4}>
          <Select
            allowClear
            className="gx-w-100"
            placeholder="Select Company"
            onChange={(item) => handleChange(item, 'company_id')}
            defaultValue={search?.company_id === '' ? null : search?.company_id}
            options={Company?.map((i) => ({ value: i.id, label: i.name }))}
          />
        </Col>
      )}
      <Col span={listType === 'own' ? 6 : 4}>
        <Select
          allowClear
          className="gx-w-100"
          placeholder="Select Category"
          onChange={(item) => handleChange(item, 'category')}
          defaultValue={search?.category === '' ? null : search?.category}
          options={ServiceCategories?.map((i) => ({ value: i.value, label: i.name }))}
        />
      </Col>
      <Col span={listType === 'own' ? 6 : 4}>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          placeholder={['From start', 'From end']}
          format={DATE_FORMAT}
          onChange={(v) => handleDateChange(v, 'submitted_range')}
          defaultValue={
            search?.submitted_range?.length > 0 && [
              moment(search?.submitted_range[0], dateFormat),
              moment(search?.submitted_range[1], dateFormat),
            ]
          }
        />
      </Col>
      <Col span={24} className="gx-mt-4">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </Col>
    </Row>
  )

  return (
    <Auxiliary>
      <Widget>{renderData()}</Widget>
      {visible && (
        <FormModal
          {...{
            visible,
            formName: 'hr-services-create-form',
            handleCancel: () => setVisible(false),
            handleOk: (values) => {
              if (editItem) {
                values.id = editItem?.id
              }
              if (values.category) {
                values.employee_id = parseInt(user?.employee_id)
                values.issue_date = moment(values.issue_date).format('DD-MM-YYYY')
              }
              if (values.to_date || values.from_date) {
                values.to_date = moment(values.to_date).format('DD-MM-YYYY')
                values.from_date = moment(values.from_date).format('DD-MM-YYYY')
              }
              return dispatch(addOrUpdateHRS(values))
            },
            title: !editItem ? 'Create HR Service' : 'Update HR Service',
            form,
            category: ServiceCategories,
            noc_type,
            selectedCategory,
            setSelectedCategory,
          }}
        />
      )}
    </Auxiliary>
  )
}

export default HRServices
