import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const EmployeeCell = ({ name, photo, eid, link = true }) => (
  <div className="gx-flex-row gx-flex-nowrap gx-align-items-center">
    <Avatar src={photo ?? 'https://'} icon={<UserOutlined />} />
    <div className="gx-mb-0 gx-ml-1">
      <h4 className={link ? 'gx-link gx-mb-0' : 'gx-mb-0'}>
        {name} {eid && <span className="gx-fs-xs gx-ml-1">({eid})</span>}
      </h4>
    </div>
  </div>
)

EmployeeCell.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
  eid: PropTypes.string,
}

EmployeeCell.defaultProps = {
  eid: '',
  photo: 'https://',
}

export default EmployeeCell
